/** @format */

import moment from 'moment'
import ApiParameters from '@/models/interface/ApiParameters'
import DatePickerDate from './DatePickerDate'
import Api from './Api'
import WebMessage from './WebMessage'

export default class Forecast {
  public date: DatePickerDate = new DatePickerDate(
    moment().format('YYYY-MM-DD'),
    moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
  )

  public devices: string[] = ['ctv', 'desktop', 'mobile']

  public publishers: string[] = []

  public inventory: string[] = [
    'sportstream',
    'sportstreamtv',
    'sportstreamlive',
    'newstream',
  ]

  public include_dmas: string[] = []

  public exclude_dmas: string[] = []

  public dimensions: string[] = ['month']

  public include_states: string[] = []

  public exclude_states: string[] = []

  public include_zipcodes: string = ''

  public exclude_zipcodes: string = ''

  public to: any = []

  public name: string = ''

  public message: string = ''

  public filter_type: string = 'default'

  public limit_impressions: boolean = true

  public result: any = {
    impressions: 0,
    live_events: 0,
  }

  public loading = false

  public update(file: any) {
    const api = new Api()

    return api
      .form('forecast/update', {
        update_file: file,
      })
      .then(this.onSave)
  }

  private buildParams(output: string | null = null) {
    const data: ApiParameters = {
      dimensions: this.dimensions,
      filters: {
        start: this.date.start,
        end: this.date.end,
        inventory: this.inventory,
        publishers: this.publishers,
        devices: this.devices,
        include_dmas: this.include_dmas,
        exclude_dmas: this.exclude_dmas,
        include_states: this.include_states,
        exclude_states: this.exclude_states,
      },
      to: this.to,
      name: this.name,
      message: this.message,
      output,
    }

    if (!output) {
      delete data.output
    }

    return data
  }

  public run() {
    const api = new Api()

    this.loading = true

    return api
      .post('forecast', this.buildParams())
      .then((response: any) => {
        this.result.impressions = response.data.result[0].impressions == null
          ? 0
          : parseInt(response.data.result[0].impressions)
        this.result.live_events = response.data.result[0].live_events == null
          ? 0
          : parseInt(response.data.result[0].live_events)

        this.loading = false
        return response
      })
      .catch(this.onError)
  }

  public fetch(output: string | null = null) {
    const api = new Api()

    return api.post('forecast', this.buildParams(output))
  }

  public download() {
    const api = new Api()

    return api
      .post('forecast', this.buildParams('xlsx'))
      .then(this.onDownload)
      .catch(this.onError)
  }

  public share() {
    const api = new Api()

    return api
      .post('forecast', this.buildParams('mail'))
      .then(this.onSend)
      .catch(this.onError)
  }

  private onDownload(response: any) {
    const target = response.data.result
    const api = new Api()
    api.download(
      `${process.env.VUE_APP_BASE_API_URL}forecast/${target}/download`,
      'forecast.xlsx',
    )
    return response
  }

  private onSend(response: any) {
    const message = 'Forecast sent!'

    WebMessage.success(message)

    return response
  }

  private onSave(response: any) {
    WebMessage.success(
      'Upload complete! The update process can take up to 10 minutes but you are free to use the Dashboard or close this window.',
    )

    return response
  }

  private onError(error: any) {
    let message
    if (error.response && error.response.status == 422) {
      for (const key in error.response.data.errors) {
        message = error.response.data.errors[key][0]
        break
      }
    }
    if (!message) {
      message = 'We were not able to update the report, please try again later.'
    }

    WebMessage.error(message)

    return Promise.reject(error)
  }
}
