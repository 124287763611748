
import { Component } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import Widget from '@/components/Widget/Widget.vue'
import Forecast from '@/models/Forecast'
import dmaCodes from '@/data/dma_options'
import stateCodes from '@/data/state_options'
import moment from 'moment'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import User from '@/models/User'
import Inventory from '@/models/Inventory'

@Component({
  components: {
    Widget,
    SelectPicker,
    DatePicker,
    IconAction,
    FormInput,
  },
})
export default class ForecastHome extends ViewModel {
  public query: Forecast = new Forecast();

  public loading: boolean = false;

  public show_report: boolean = false;

  public data: object[] | null = [];

  public fields: object[] = [];

  public sortBy: string = 'date';

  public sortDesc: boolean = true;

  public option_tos: object[] = [];

  public country_id: string = '231';

  public min_date: string = moment().format('YYYY-MM-DD');

  public modal: any = {
    send: false,
  };

  public devices_options = [
    {
      name: 'Connected TV',
      value: 'ctv',
    },
    {
      name: 'Desktop',
      value: 'desktop',
    },
    {
      name: 'Mobile',
      value: 'mobile',
    },
  ];

  public dimensions_options = [
    {
      name: 'Day',
      value: 'day',
    },
    {
      name: 'Month',
      value: 'month',
    },
    {
      name: 'Inventory',
      value: 'inventory',
    },
    {
      name: 'Devices',
      value: 'device',
    },
    {
      name: 'State',
      value: 'state',
    },
    {
      name: 'DMAs',
      value: 'dma',
    },
  ];

  public get inventory_options() {
    return Inventory.module.type_options
  }

  public get state_options() {
    return stateCodes
  }

  public get dma_options() {
    return dmaCodes
  }

  public show_total_events: boolean = false;

  public addTag(newTag: string) {
    const tag = {
      name: newTag,
      value: newTag,
    }
    this.option_tos.push(tag)
    this.query.to.push(newTag)
  }

  public getTotal(key: string) {
    let total = 0
    let count = 0
    for (const i in this.data) {
      if (key == 'completion_rate') {
        // @ts-ignore
        total += parseFloat(this.data[i][key])
      } else {
        // @ts-ignore
        total += parseInt(this.data[i][key])
      }
      count++
    }

    if (key == 'completion_rate') {
      total /= count
    }

    return total
  }

  public updateData() {
    this.$router.push('/app/forecast/update')
  }

  public run() {
    if (this.show_report) {
      // @ts-ignore
      this.$refs.table.refresh()
    }
    this.show_report = true
  }

  public book() {
    this.system.updateState({
      name: 'forecast',
      data: this.query,
    })
    this.$router.push({ name: 'BookingEdit' })
  }

  public download() {
    this.loading = true
    this.query.download().then(() => (this.loading = false))
  }

  public share() {
    this.modal.send = true
  }

  public handleShareOk() {
    // @ts-ignore
    this.$refs['share-form'].validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.shareConfirm()
    })
  }

  public shareConfirm() {
    this.modal.send = false
    this.loading = true
    this.query
      .share()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false))
  }

  public dataProvider() {
    this.show_total_events = !this.query.dimensions.includes('dma')
      && !this.query.dimensions.includes('device')

    return this.query
      .fetch()
      .then((response: any) => {
        this.data = null
        this.data = response.data.result

        this.fields = []

        for (const key in response.data.result[0]) {
          this.fields.push({
            key,
            sortable: true,
            class: 'text-center',
          })
        }

        return this.data
      })
      .catch(() => [])
  }

  public inventoryName(value: string) {
    return Inventory.module.type_options.find(o => o.value == value)?.name
  }

  public created() {
    // User.module.email_options.forEach(item => {
    //   this.option_tos.push(item)
    // })
  }
}
